if (document.body.classList.contains('page-template-template-configuratore'))
{

	const choices       = document.querySelectorAll('.choice');
	const machines      = document.querySelector('.machines');
	const prevButton    = document.querySelector('.configurator-nav .btn--prev');
	const nextButton    = document.querySelector('.configurator-nav .btn--next');
	const exportButton  = document.querySelector('.configurator-nav .btn--export');
	const restartButton = document.querySelector('.configurator-nav .btn--restart');

	let activeSection   = 1;
	let choicesSequence = [];

	choices.forEach(function (choice) {

		choice.addEventListener('click', function () {
			const section = this.closest('.configurator-section');

			section.querySelectorAll('.choice').forEach(function (el) {
				el.classList.remove('active');
			});

			this.classList.add('active');

			// enable next button
			nextButton.disabled = false;

			// if it's the last step...
			if (activeSection === 4)
			{
				const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
				const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
				const currentChoice  = currentSection.querySelector('.choice.active');

				// get image and title from currentChoice
				const currentChoiceImg   = currentChoice.querySelector('svg').cloneNode(true);
				const currentChoiceTitle = currentChoice.querySelector('.title').textContent;
				const currentChoiceValue = choice.dataset.choice;

				// remove image from currentStep, if present
				const currentStepImg = currentStep.querySelector('.step__info div:nth-child(2) svg');
				if (currentStepImg !== null)
				{
					currentStepImg.remove();
				}

				// set image and title in current step with values from current choice
				currentStep.querySelector('.step__info div:nth-child(2)').prepend(currentChoiceImg);
				currentStep.querySelector('.step__info div:nth-child(2) span').innerHTML = currentChoiceTitle;

				// if the user already made a choice in the last step, remove the last choice in the sequence before adding the new one
				if (choicesSequence.length === 4)
				{
					choicesSequence.pop();
				}

				// add new choice to sequence
				choicesSequence.push(currentChoiceValue);

				const choicesSequenceValues = choicesSequence.join(',');

				// show the machines related to the choices made, and remove the others
				machines.querySelectorAll('[data-show-if]').forEach(function (el) {
					const showIf = el.dataset.showIf.split('|');

					if (showIf.includes(choicesSequenceValues))
					{
						el.style.display = 'block';
					}
					else
					{
						el.style.display = 'none';
					}
				});

				exportButton.disabled = false;
			}
		});

	});


	nextButton.addEventListener('click', function () {
		const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const currentChoice  = currentSection.querySelector('.choice.active');

		// get image and title from currentChoice
		const currentChoiceImg   = currentChoice.querySelector('svg').cloneNode(true);
		const currentChoiceTitle = currentChoice.querySelector('.title').textContent;
		const currentChoiceValue = currentChoice.dataset.choice;

		// add current choice to choicesSequence
		choicesSequence.push(currentChoiceValue);

		// set image and title in current step with values from current choice
		currentStep.querySelector('.step__info div:nth-child(2)').prepend(currentChoiceImg);
		currentStep.querySelector('.step__info div:nth-child(2) span').innerHTML = currentChoiceTitle;

		// change current step into past step
		currentStep.classList.remove('step--current');
		currentStep.classList.add('step--past');

		// hide current section
		currentSection.style.display = 'none';

		// set next section
		activeSection++;
		const nextSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const nextStep    = document.querySelector('.step[data-step="' + activeSection + '"]');

		// change next step into current step
		nextStep.classList.remove('step--future');
		nextStep.classList.add('step--current');

		// show next section
		nextSection.style.display = 'block';

		// show only choices related to chosen value
		nextSection.querySelectorAll('[data-show-if]').forEach(function (el) {

			// skip the machines
			if (el.closest('.machines') !== null)
			{
				return;
			}

			const showIf = el.dataset.showIf.split(',');

			if (showIf.includes(currentChoiceValue))
			{
				el.style.display = 'block';
			}
		});

		// disable next button
		nextButton.disabled = true;

		// show prev button
		prevButton.style.display    = 'inline-flex';
		restartButton.style.display = 'inline-flex';

		// if it's the last step, hide next button and show export button
		if (activeSection === 4)
		{
			nextButton.style.display   = 'none';
			exportButton.style.display = 'inline-flex';
			exportButton.disabled      = true;
		}
	});


	prevButton.addEventListener('click', function () {
		const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const currentChoice  = currentSection.querySelector('.choice.active');

		// reset current choice
		if (currentChoice !== null)
		{
			currentChoice.classList.remove('active');
		}

		// if the user made a choice in step 4, remove the choice
		if (choicesSequence.length === 4)
		{
			choicesSequence.pop();
		}

		// remove last choice from choicesSequence
		choicesSequence.pop();

		// change current step into future step
		currentStep.classList.remove('step--current');
		currentStep.classList.add('step--future');

		// hide current section
		currentSection.style.display = 'none';

		// show only choices related to chosen value
		currentSection.querySelectorAll('[data-show-if]').forEach(function (el) {
			el.style.display = 'none';
		});

		// set prev section
		activeSection--;
		const prevSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const prevStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const prevChoice  = prevSection.querySelector('.choice.active');

		// reset prev choice
		prevChoice.classList.remove('active');

		// empty title and remove image in prev step
		prevStep.querySelector('.step__info div:nth-child(2) svg').remove();
		prevStep.querySelector('.step__info div:nth-child(2) span').innerHTML = '';

		// change prev step into current step
		prevStep.classList.remove('step--past');
		prevStep.classList.add('step--current');

		// show prev section
		prevSection.style.display = 'block';

		// hide prev button if first section
		if (activeSection === 1)
		{
			prevButton.style.display    = 'none';
			restartButton.style.display = 'none';
		}

		// if it's the step before the last, show next button and hide export button
		if (activeSection === 3)
		{
			nextButton.style.display   = 'inline-flex';
			nextButton.disabled        = true;
			exportButton.style.display = 'none';
		}
	});


	exportButton.addEventListener('click', function () {
		window.print();
	});

	restartButton.addEventListener('click', function () {
		location.reload();
	});
}